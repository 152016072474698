<template>
    <div class="wscn-http404-container">
        <div class="wscn-http404">
            <div class="pic-404">
                <img class="pic-404__parent" src="@/assets/404_images/404.png" alt="404">
                <img class="pic-404__child left" src="@/assets/404_images/404_cloud.png" alt="404">
                <img class="pic-404__child mid" src="@/assets/404_images/404_cloud.png" alt="404">
                <img class="pic-404__child right" src="@/assets/404_images/404_cloud.png" alt="404">
            </div>
            <div class="bullshit">
                <div class="bullshit__oops">注意!</div>

                <div class="bullshit__headline">{{ message }}</div>
                <div class="bullshit__info">请检查您输入的URL是否正确，或单击下面的按钮返回首页。</div>
                <a href="/" class="bullshit__return-home">回到首页</a>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Page404',
        computed: {
            message() {
                return '网站管理员说您无法进入此页面...'
            }
        }
    }
</script>

<style lang="scss" >
 
</style>
